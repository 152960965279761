@use 'mixins/modals-mixins';

.create-account-login-container {
  @include modals-mixins.default-modal;
  @include modals-mixins.blue-modal;

  @media only screen and (width <= 767px) {
    padding: 17px 10px 30px;
  }

  .close-modal {
    background-image: url('/assets/icon/white-close.svg');
    background-size: cover;
    width: 29px;
    height: 29px;
    transition: 0.3s cubic-bezier(0, 0.82, 0.83, 0.67);

    &:hover {
      background-image: url('/assets/icon/yellow-close.svg');
    }
  }

  app-authentication {
    height: 100%;
    width: 100%;
  }

  .title {
    color: var(--wr-color-white);
    font-size: var(--wr-size-20);
    font-weight: 500;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;

    &.centered {
      justify-content: center;
    }

    .previous {
      font-size: 35px;
      cursor: pointer;
      background-image: url('/assets/icon/left-arrow-bold.svg');
      background-size: cover;
      width: 25px;
      height: 25px;
      transition: 0.3s cubic-bezier(0, 0.82, 0.83, 0.67);

      &:hover {
        background-image: url('/assets/icon/left-arrow-bold-yellow.svg');
      }
    }
  }
}
