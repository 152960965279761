<div class="select-category-container" data-cy="select-category-desktop">
  <div data-cy="select-category-desktop-modal-close-btn" class="close-modal" (click)="closeModal()">
    <img src="assets/icon/blue-close.svg" alt="" />
  </div>

  <div class="title">{{ 'PWA_homeLibrary_filters_categories_header' | translate }}</div>

  <div class="options">
    <ion-radio-group [value]="selectedCategory" (ionChange)="onSelectedCategoryChange($event)" value="space-between">
      @for (category of booksService.categoriesBooks$ | async; track $index) {
        <div class="option" data-cy="select-category-desktop-modal-option">
          <ion-radio mode="md" value="space-between" justify="space-between" [value]="category">
            <div class="name">
              {{ backendTranslate(category.name) }}
            </div>
          </ion-radio>
        </div>
      }
    </ion-radio-group>
  </div>

  <app-button [label]="'PWA_categories_popup_CTA_letsGo' | translate" data-cy="select-category-desktop-modal-confirm-btn" (clicked)="applyCategoryChange()" />

  <div data-cy="select-category-desktop-modal-cancel-btn" class="cancel" (click)="closeModal()">
    <div>{{ 'PWA_categories_popup_CTA_link_cancel' | translate }}</div>
  </div>
</div>
