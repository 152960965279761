@mixin default-modal() {
  padding: 32px 40px 30px;
  position: relative;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media only screen and (width <= 767px) {
    padding: 30px 10px;
  }

  .close-modal {
    position: absolute;
    top: calc(12px + env(safe-area-inset-top));
    right: 12px;
    cursor: pointer;

    &:dir(rtl) {
      right: auto;
      left: 12px;
    }

    img {
      width: 29px;
    }
  }

  .title {
    font-size: var(--wr-size-18);
    color: var(--wr-color-blue);
    font-weight: 600;
    margin-bottom: 30px;
  }

  .description {
    color: var(--wr-color-grey);
    font-weight: 400;
    font-size: var(--wr-size-14);
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .cancel {
    font-weight: 700;
    font-size: var(--wr-size-12);
    color: var(--wr-color-blue);
    cursor: pointer;
    margin-top: 15px;
  }
}

@mixin blue-modal {
  background-color: var(--wr-color-dark-blue);
  color: var(--wr-color-white);

  .title,
  .cancel,
  .description {
    color: var(--wr-color-white);
  }
}

@mixin yellow-modal() {
  background-color: var(--wr-color-yellow);
  color: var(--wr-color-white);
}

@mixin white-modal() {
  background-color: var(--wr-color-white);
}
