import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BookShelfComponent } from '../book-shelf/book-shelf.component';
import { BooksService, ShelfType } from '../../services/books.service';
import { BookCategory } from '../../models/book.model';
import { filter, Observable, switchMap } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-book-categories-shelves',
  templateUrl: './book-categories-shelves.component.html',
  styleUrls: ['./book-categories-shelves.component.scss'],
  imports: [BookShelfComponent, AsyncPipe],
})
export class BookCategoriesShelvesComponent {
  constructor(private booksService: BooksService) {}

  bookCategories$: Observable<BookCategory[]> = this.booksService.initialized$.pipe(
    filter(initialized => initialized),
    switchMap(() => this.booksService.categoriesBooks$),
  );

  readonly shelfType = ShelfType;
  protected readonly ShelfType = ShelfType;
}
